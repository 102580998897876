import React from "react";
import styles from "./ExampleQuestion.module.css";

interface Props {
    text: string;
    value: string;
    onClick: (value: string) => void;
}

const ExampleQuestion: React.FC<Props> = ({ text, value, onClick }) => {
    return (
        <div className={`box ${styles.customHoverBox}`} style={{ backgroundColor: "#F4F4F4", cursor: "pointer" }} onClick={() => onClick(value)}>
            <p className="title is-5" style={{ color: "#D563E0" }}>
                Example question
            </p>
            <p className="has-text-black is-6" style={{ width: "280px", height: "100px", margin: "0" }}>
                {text}
            </p>
        </div>
    );
};

export default ExampleQuestion;
