import React, { useState } from "react";
import styles from "./About.module.css";
import { mergeStyles, Text } from "@fluentui/react";
import styled from "@emotion/styled";
import "bulma/css/bulma.min.css";

interface Company {
    title: string;
    description: string;
    dataInput: string;
    questions: Record<string, string[]>;
}

// Define Emotion styled components
const Container = styled.div`
    /* Add custom styles here */
    padding: 5%;
`;

const Subtitle = styled.h2`
    /* Add custom styles for subtitles */
    font-size: 1.5rem;
    color: #333; /* Customize the color */
    cursor: pointer; /* Add a pointer cursor */
    transition: color 0.3s ease-in-out; /* Add a smooth color transition */
    margin-bottom: 0.5rem; /* Add some spacing */

    &:hover {
        color: #0078d4; /* Change color on hover */
    }
`;

const Description = styled.p`
    /* Add custom styles for descriptions */
`;

const DataInput = styled.p`
    /* Add custom styles for data input */
`;

const SectionTitle = styled.h3`
    /* Add custom styles for section titles */
`;

const Question = styled.li`
    /* Add custom styles for questions */
`;

const CompanyData: Record<string, Company> = {
    "energy-production": {
        title: "Energy Production",
        description:
            "Energy Production VA is giving us information on a wide range of topics, including U.S. energy production and consumption trends, The changing mix of energy sources in the United States, The impact of technological advances on the U.S. energy sector, The role of energy policy in shaping the U.S. energy future. Input data is a report that provides information about the supply, use, and export of various energy sources, including coal, nuclear power, natural gas, crude oil, and pipelines, this report also concludes by discussing the key challenges and opportunities facing the U.S. energy sector in the coming decades. ",
        dataInput: "https://sgp.fas.org/crs/misc/R46723.pdf",
        questions: {
            "Energy Sources": [
                "What are the major sources of energy in the United States?",
                "How has the mix of energy sources changed over the 21st century?",
                "What role does renewable energy play in the U.S. energy landscape?"
            ],
            "Energy Policy": [
                "What key policies have influenced U.S. energy development in recent years?",
                "How is the U.S. working to address environmental concerns related to energy production?",
                "Can you provide an overview of the regulatory framework governing the energy sector?"
            ]
        }
    },
    aerospace: {
        title: "Aerospace",
        description:
            "Aerospace VA offers assistance to aerospace engineers and scientists by providing them with Basic Flight Information and ATC Procedures from the Aeronautical Information Manual Official Guide, which is prepared by Administration U.S. Department of Transportation Federal Aviation. We have compiled this data/manual which contains the fundamentals required to fly in the United States NAS. It also includes useful information for pilots such as health and medical facts, factors affecting flight safety, a pilot/controller glossary of terms used in the ATC System, and details on safety, accident, and hazard reporting.",
        dataInput: "https://www.faa.gov/air_traffic/publications/media/aim_basic_dtd_10-12-17.pdf",
        questions: {
            "Basic Flight Information": [
                "What are the basic components of a flight plan?",
                "Can you explain the concept of airspace and its different classes?",
                "What are the key responsibilities of a pilot during different phases of flight?"
            ],
            "ATC Procedures": [
                "How does air traffic control manage the flow of air traffic at airports?",
                "What is the significance of clearances in air traffic control?",
                "Can you explain the procedures for communication between pilots and air traffic controllers?"
            ]
        }
    },
    "mental-health-nurse": {
        title: "Mental Health Nurse",
        description:
            "Mental Health Nurse VA will be designed to be accessible to students and practitioners who want to learn more about psychiatric and mental health nursing. We are feeding a comprehensive textbook as an input to our system. This book covers the theory and practice of psychiatric and mental health nursing. It is written for nursing students and practitioners who are interested in learning more about this specialized field of nursing. Also, part of this book covers major psychiatric and mental health disorders, including their assessment, diagnosis, and treatment along with some  advanced topics in psychiatric and mental health nursing, such as cultural competence, evidence-based practice, and forensic nursing. ",
        dataInput:
            "https://repository.poltekkes-kaltim.ac.id/625/1/Psychiatric%20Mental%20Health%20Nursing%20Concepts%20of%20Care%20in%20Evidence-Based%20Practice%20by%20Mary%20C.%20Townsend%20DSN%20%20PMHCNS-BC%20(z-lib.org).pdf",
        questions: {
            "Mental Health Assessment": [
                "Can you explain the key components of a mental health assessment?",
                "How does the assessment process differ for different age groups or populations?",
                "What are some common screening tools used in mental health nursing, and how do they help in the assessment process?"
            ],
            "Mental Health Conditions": [
                "Can you provide an overview of common mental health disorders and their symptoms?",
                "How do you differentiate between different types of anxiety disorders, mood disorders, and psychotic disorders during the assessment?",
                "What are the risk factors and protective factors associated with mental health conditions?"
            ]
        }
    },
    "police-officer": {
        title: "Police Officer",
        description:
            "Police Officer VA will give information about the role and responsibilities of police officers. Including difficulties faced by police, Level and Pattern of crime, improving the Safety of Communities, enhancing Patrol and involving the Private Security Industry. It also includes the Organisation of the Police Service along with police Performance and Accountability. We have fed the document prepared by the police foundation and police studies institute in the United Kingdom.  ",
        dataInput: "https://www.police-foundation.org.uk/wp-content/uploads/2017/06/roles_and_resp.pdf",
        questions: {
            "Law Enforcement Roles": [
                "What are the primary roles of law enforcement officers in the UK?",
                "How is community policing emphasized in the roles and responsibilities of police officers?",
                "Can you explain the collaboration between police and other agencies outlined in the manual?"
            ],
            "Use of Force Policies": [
                "What guidelines does the manual provide regarding the use of force by police officers?",
                "How is accountability addressed in the context of law enforcement duties?",
                "What training requirements are highlighted for police officers in the manual?"
            ]
        }
    },
    transportation: {
        title: "Transportation",
        description:
            "Transportation VA is a valuable resource for transportation professionals covering the economics of transportation systems, including costs, benefits, pricing, regulation, competition, and investment. The reference book provides an overview of economic principles and analytical methods relevant to transportation decision-making, making it an essential resource for anyone interested in transportation economics.",
        dataInput: "https://library.ctr.utexas.edu/ctr-publications/0-6628-p1.pdf",
        questions: {
            "Transportation Economics": [
                "How do transportation systems contribute to the overall economy?",
                "Can you explain the economic impact of different modes of transportation (e.g., road, rail, air)?",
                "What role do government policies play in shaping the economics of transportation?"
            ],
            "Infrastructure Investment": [
                "How does the economics of transportation influence decisions related to infrastructure investment?",
                "What are the challenges and opportunities associated with funding transportation projects?",
                "Can you provide examples of successful transportation economic strategies from different regions?"
            ]
        }
    },
    "hotel-concierge": {
        title: "Hotel Concierge",
        description:
            "This vertical provides information about Santa Monica, including its attractions, beaches, shopping districts, dining options, transportation, events, local culture, safety, historical information, and practical tips for visitors.",
        dataInput: "https://issuu.com/lamcs/docs/sm_ovg_nov21rev",
        questions: {
            "General Information": ["Can you provide an overview of Santa Monica, including its location and key features?"],
            "Attractions and Landmarks": [
                "What are the must-visit attractions in Santa Monica?",
                "Tell me about the Santa Monica Pier and its attractions.",
                "Are there any famous landmarks in Santa Monica?"
            ],
            "Beaches and Recreation": [
                "What are the popular beaches in Santa Monica?",
                "Are there any recreational activities available on the beaches?",
                "Can you recommend outdoor activities in Santa Monica?"
            ],
            "Shopping and Dining": [
                "Where are the best shopping districts or malls in Santa Monica?",
                "What are some popular restaurants or cafes in the area?",
                "Are there any local specialties or famous dishes I should try?"
            ],
            Transportation: [
                "How is the public transportation system in Santa Monica?",
                "Are there bike rental options available?",
                "What is the parking situation like in Santa Monica?"
            ],
            "Events and Entertainment": [
                "Are there any upcoming events or festivals in Santa Monica?",
                "What is the nightlife like in the area?",
                "Can you recommend entertainment venues or theatres?"
            ],
            "Local Culture": [
                "Tell me about the cultural scene in Santa Monica.",
                "Are there any museums or art galleries worth visiting?",
                "What local events or traditions should I be aware of?"
            ],
            "Community and Safety": [
                "How would you describe the community atmosphere in Santa Monica?",
                "Is Santa Monica considered a safe place to visit?",
                "Are there any specific neighborhoods I should explore or avoid?"
            ],
            "Historical Information": [
                "What is the history of Santa Monica?",
                "Are there any historical sites or buildings with significance?",
                "How has the city evolved over the years?"
            ],
            "Practical Information": [
                "What is the best time to visit Santa Monica?",
                "Can you provide information about local public services, like hospitals or police stations?",
                "Are there any specific local rules or regulations I should be aware of?"
            ]
        }
    },
    "real-estate-agent": {
        title: "Real Estate Agent",
        description:
            "The Real Estate Agent VA offers insights into Greystar, its properties in London, sustainability objectives, available flats, and transportation links. Explore details about Greystar Real Estate Partners and its operations in different countries.",
        dataInput: "N/A",
        questions: {
            "General Questions": [
                "Who is Greystar?",
                "What are Greystar’s sustainability objectives?",
                "What are some of the measures mentioned in the source?",
                "What flats are available in north London?",
                "When was Greystar Real Estate Partners founded, and who is the founder?",
                "In which countries does Greystar operate?",
                "What benefits and perks does Greystar offer to residents in the EU?",
                "Can you provide more information about Tulu and how it works?",
                "Is there an additional cost to use Tulu?",
                "Can you tell me more about the acquisition in Stratford?",
                "When is the expected completion date?",
                "What are the closest stations?",
                "How is Arsenal as an Area?",
                "How do I get to Startford from Arsenal?",
                "What is there to do in Startford?"
            ]
        }
    },
    "aircraft-engineer": {
        title: "Aircraft Engineer",
        description:
            "We have ingested 8 training manuals into the index (provided to us by MyTechnic). These manuals cover the following areas to do with aircraft engineering: General Aircraft ,Equipment & furnishings,Nitrogen Generated System,Electrical Power,Communications,Auto flight,Hydraulic Power,Landing Gear,Fuel,Navigation,Flight controls,Engine Systems,Weather protection",
        dataInput:
            "https://resolabs.sharepoint.com/sites/ClarifyInnovationLab/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FClarifyInnovationLab%2FShared%20Documents%2FGeneral%2FMyTechnic%2FManuals&p=true&ct=1698920718830&or=Teams%2DHL&ga=1&LOF=1Connect your OneDrive account",
        questions: {}
    },
    "financial-planner": {
        title: "Financial Planner",
        description:
            "Personal Financial Planning VA specializes in helping individuals with their personal financial planning needs. This can include topics such as Budgeting and expense tracking, Tax Planning, Debt management, Investment planning, Retirement planning and Estate planning etc. We have ingested the book called 'Personal Financial Planning' authored by Lewis J. Altfest which was provided by Dan Donovan.",
        dataInput: "https://resolabs-my.sharepoint.com/:b:/p/vagif/EcsJk7XWQzxBsPSoVXcypRMBuRmuKTIbv-0sSEc5j5c9lg?e=b52vWW",
        questions: {
            "General Financial Planning: Basics": [
                "What are the key principles of personal financial planning, and how can I apply them to my situation?",
                "How do I create a realistic and effective budget for my finances?",
                "Can you provide tips for managing debt and improving my credit score?"
            ],
            "Investment and Wealth Building": [
                "What are the different types of investment options available to individuals, and how do I determine which ones are suitable for my goals?",
                "How can I diversify my investment portfolio to minimize risk?",
                "What role does risk tolerance play in investment decisions, and how do I assess my risk tolerance?"
            ],
            "Retirement Planning": [
                "How much should I be saving for retirement, and what are the best retirement savings vehicles for me?",
                "What are the implications of starting retirement planning early versus starting later in life?",
                "Can you explain the concept of Social Security and how it factors into retirement planning?"
            ],
            "Tax Planning": [
                "What are some effective tax planning strategies for individuals?",
                "How can I optimize my tax returns and take advantage of available tax credits and deductions?"
            ],
            "Emergency Fund and Insurance": [
                "What is the importance of having an emergency fund, and how much should I aim to save in it?",
                "What types of insurance (e.g., life, health, disability) are essential for personal financial security, and how do I choose the right coverage?"
            ],
            "Estate Planning": [
                "What is estate planning, and when should I start considering it?",
                "How can I ensure the smooth transfer of assets to my beneficiaries and minimize estate taxes?"
            ],
            "Financial Education planning and Resources": [
                "Are there any recommended books, websites, or resources for improving financial literacy and understanding personal finance better?",
                "How can I stay informed about changes in the financial market and adjust my financial plan accordingly?"
            ],
            "Debt Management": [
                "What strategies can I use to pay off high-interest debt more efficiently?",
                "When does it make sense to consider debt consolidation, and what are the potential benefits and drawbacks?"
            ],
            "Cash Flow Planning": [
                "How can I improve my long-term cash flow position?",
                "What are some strategies for growing business and improving cash flow?",
                "Where can I cut back on spending?"
            ]
        }
    },
    "veterans-affairs": {
        title: "Veterans Affairs (VA)",
        description:
            "Veterans Affairs' is a virtual assistant designed to provide information and assistance to veterans regarding their healthcare benefits and related services. These virtual assistants, often available on websites or mobile applications, are programmed to offer guidance, answer frequently asked questions, and help veterans navigate the complexities of the Veterans Affairs (VA) healthcare system",
        dataInput: "https://www.va.gov/healthbenefits/vhbh/publications/vhbh_sample_handbook_2014.pdf",
        questions: {
            "Application Process": [
                "How can veterans apply for health benefits from the VA?",
                "What documents are required during the application process?",
                "Is there an online application option?"
            ],
            "Coverage and Services": [
                "What medical services are covered under veterans' health benefits?",
                "Are there limitations or exclusions to the coverage?",
                "Can veterans receive dental and vision care through the VA?"
            ],
            "Costs and Fees": [
                "Are there any out-of-pocket costs for veterans receiving health benefits?",
                "How does the VA handle co-payments and deductibles?",
                "Are there financial assistance programs for veterans with limited resources?"
            ],
            "Primary Care and Specialized Services": [
                "How does the VA provide primary care services to veterans?",
                "Are there specialized services available for certain health conditions?",
                "Can veterans receive mental health services through the VA?"
            ],
            "Prescription Medications": [
                "How does the VA handle prescription medications for veterans?",
                "Are there co-payments for prescription drugs?",
                "Can veterans get medications through mail-order services?"
            ],
            "Access to VA Facilities": [
                "Where are the nearest VA medical facilities or clinics?",
                "How can veterans schedule appointments for medical care?",
                "Are there urgent care services available for veterans?"
            ],
            "Community Care Programs": [
                "What is the VA Community Care program, and how does it work?",
                "Can veterans receive care from non-VA providers under certain circumstances?",
                "What criteria determine eligibility for community care?"
            ],
            "Coverage for Dependents": ["Do veterans' health benefits extend to their dependents?", "How are dependents covered under VA health care?"],
            "Appeals and Grievances": [
                "What is the process for appealing decisions related to veterans' health benefits?",
                "How can veterans address grievances or concerns about their care?",
                "Are there advocacy services available to assist veterans in navigating the system?"
            ],
            "Updates and Notifications": [
                "How can veterans stay informed about changes or updates to their health benefits?",
                "Are there newsletters or online platforms that provide relevant information?",
                "Can veterans opt to receive notifications about important updates?"
            ]
        }
    }
};

const About = () => {
    const [activeCompany, setActiveCompany] = useState<string | null>(null);

    const toggleCompany = (companyKey: string) => {
        setActiveCompany(activeCompany === companyKey ? null : companyKey);
    };

    return (
        <section className="section">
            <div className="container">
                <h1 className="title is-2">About Us</h1>
                <div className="content">
                    <p>
                        Welcome to our comprehensive MultiVA documentation! We've diligently curated a wealth of knowledge across various domains, saving your
                        company the time and effort of sifting through extensive manuals. Our virtual assistant is at your service, ready to answer any
                        questions you may have with precision and speed. Whether you're exploring energy production, aerospace, mental health nursing, law
                        enforcement, transportation, hospitality, real estate, aircraft engineering, or financial planning, our MultiVA is here to empower your
                        organization with invaluable insights. Simply ask your questions, and our assistant will provide the information you need, making your
                        work more efficient and productive.
                    </p>
                </div>
                <hr />
                {Object.keys(CompanyData).map(companyKey => {
                    const company = CompanyData[companyKey];
                    const isActive = activeCompany === companyKey;

                    return (
                        <div key={companyKey} style={{ marginBottom: "2rem" }}>
                            {" "}
                            {/* Increased space between company sections */}
                            <h2 className="subtitle is-4" onClick={() => toggleCompany(companyKey)}>
                                {company.title}
                            </h2>
                            {isActive && (
                                <div className="box">
                                    <p>{company.description}</p>
                                    <p>
                                        <strong>Data input:</strong> {/* Bold the "Data Input" text */}
                                        <a href={company.dataInput}>{company.dataInput}</a>
                                    </p>
                                    <ul>
                                        {Object.keys(company.questions).map(section => (
                                            <li key={section}>
                                                <p className="menu-label">{section}</p>
                                                <ul>
                                                    {company.questions[section].map(question => (
                                                        <li key={question}>{question}</li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default About;
