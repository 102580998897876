import React, { useEffect, useState } from "react";
import { Outlet, NavLink, Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import styles from "./Layout.module.css";
import config from "../../../roles.json";
import { LayoutProps } from "../../interfaces/componentProperties";
import RoleSelectionDropdown from "../../components/RoleSelector/RoleSelectionDropdown";

const Layout: React.FC<LayoutProps> = ({ selectedRole }) => {
    const location = useLocation();

    const [roleName, setRoleName] = useState("");

    useEffect(() => {
        const roleName = config.roles[selectedRole as keyof typeof config.roles]?.name || config.roles.default.name;
        setRoleName(roleName);
        Cookies.set("brandingLogo", selectedRole, { path: "/" });
    }, [selectedRole, location.search]);

    const activeTabClasses: string = "navbar-item has-text-weight-semibold has-background-white has-text-black";

    return (
        <div className={styles.layout}>
            <nav className={`${styles.navbar} navbar is-black has-navbar-fixed-top is-fixed-top`} role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link to="/" className="navbar-item">
                        <span className="has-text-weight-bold has-text-white">{roleName}</span>
                    </Link>
                </div>

                <div className="navbar-menu">
                    <div className="navbar-start">
                        <NavLink to="/" className={({ isActive }) => (isActive ? activeTabClasses : "navbar-item")}>
                            Home
                        </NavLink>
                        <NavLink to={"/about/?role=" + selectedRole} className={({ isActive }) => (isActive ? activeTabClasses : "navbar-item")}>
                            About
                        </NavLink>
                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item">
                            <RoleSelectionDropdown />
                        </div>
                    </div>
                </div>
            </nav>

            {/* Content below navbar */}
            <div className={styles.outletContainer}>
                <Outlet />
            </div>

            {/* Footer */}
            <footer className={styles.footer}>
                <div className="content has-text-centered">
                    <p style={{ margin: "0" }}>© Copyright Mesmerise Solution UK Ltd 2024</p>
                </div>
            </footer>
        </div>
    );
};

export default Layout;
