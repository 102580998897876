import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Roles } from '../../../roles';
import rolesJson from '../../../roles.json';

const RoleSelectionDropdown = () => {

    const config: Roles = rolesJson;
    const [selectedRole, setSelectedRole] = useState('');
    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const roleKey = event.target.value;
        setSelectedRole(roleKey);
        navigate(`/?role=${roleKey}`);
    };

    return (
        <div className="select ml-3 mr-3">
            <select style={{ minWidth: '200px' }} value={selectedRole} onChange={handleChange}>
                <option value="">Switch to another role</option>
                {Object.keys(config.roles)
                    .filter(role => role !== "default" && role !== selectedRole)
                    .map((roleKey) => (
                    <option key={roleKey} value={roleKey}>
                        {config.roles[roleKey]?.name || (roleKey + " (Name not defined)")}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default RoleSelectionDropdown;
