import { Text } from "@fluentui/react";
import { Delete24Regular } from "@fluentui/react-icons";
import styles from "./ClearChatButton.module.css";

interface Props {
    className?: string;
    onClick: () => void; // Retain the original onClick prop
    disabled?: boolean;
}

export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    const handleClearChat = () => {
        // Call the original onClick prop function
        if (onClick) {
            onClick();
        }

        // Dispatch the custom event for clearing chat
        window.dispatchEvent(new CustomEvent("clearChatEvent"));
    };

    return (
        <div className={`${styles.container} ${className ?? ""} ${disabled && styles.disabled}`} onClick={handleClearChat}>
            <Delete24Regular />
            <Text>{"Clear chat"}</Text>
        </div>
    );
};
