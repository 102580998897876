import ExampleQuestion from "./ExampleQuestion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import config from "../../../roles.json";

export type ExampleModel = {
    text: string;
    value: string;
};

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleQuestionList = ({ onExampleClicked }: Props) => {
    const [examples, setExamples] = useState<ExampleModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            const searchParams = new URLSearchParams(location.search);
            const exampleParam = searchParams.get("role");
            const selectedRole = exampleParam ? config.roles[exampleParam as keyof typeof config.roles] : null;
            const questions = selectedRole ? selectedRole.questions : [];
            const updatedExamples: ExampleModel[] = questions.map(question => ({
                text: question,
                value: question
            }));
            setExamples(updatedExamples);
            setIsLoading(false);
        }, 100);
    }, [location.search]); // location.search in dependency array (so it runs whenever the URI changes)

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="columns is-multiline">
                {examples.map((example, index) => (
                    <div className="column" key={index}>
                        <ExampleQuestion text={example.text} value={example.value} onClick={onExampleClicked} />
                    </div>
                ))}
            </div>
        </>
    );
};
