import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import App from "./app";
import "./index.css";

const RootComponent = () => {
    return (
        <Router>
            <App />
        </Router>
    );
};

ReactDOM.render(<RootComponent />, document.getElementById("root"));
