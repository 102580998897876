import { Link } from "react-router-dom";
import { Roles } from "../../../roles";
import rolesJson from "../../../roles.json";
import "bulma/css/bulma.min.css";
import RoleSelectionBox from "../../components/RoleSelector/RoleSelectionBox";
import React, { useState } from "react";
import "./Welcome.css";

// const Welcome = () => {
//     const config: Roles = rolesJson;

//     return (
//         <>
//             <section className="section">
//                 <div className="container">
//                     <h1 className="title is-4 has-text-black">The next-generation, enterprise-ready AI - trained on your internal data</h1>
//                     <h1 className="subtitle">Which specialist would you like to speak with today?</h1>

//                     {/* Multiline Columns for role Boxes */}
//                     <div className="columns is-multiline">
//                         {Object.keys(config.roles)
//                             .filter(roleKey => roleKey !== "default")
//                             .map(roleKey => (
//                                 <div key={roleKey} className="column is-one-third">
//                                     <Link to={`/?role=${roleKey}`}>
//                                         <RoleSelectionBox name={config.roles[roleKey].name} intro={config.roles[roleKey].intro} />
//                                     </Link>
//                                 </div>
//                             ))}
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

const Welcome = () => {
    const config: Roles = rolesJson;

    // State to track whether to show all roles or not
    const [showAll, setShowAll] = useState(false);

    // Define the main roles to show initially
    const mainRoles = ["financial-planner", "human-resources", "veterans-affairs"];

    // Get the roles to display initially and when "More" is clicked
    const initialRoles = Object.keys(config.roles).filter(roleKey => mainRoles.includes(roleKey));
    const remainingRoles = Object.keys(config.roles).filter(roleKey => !mainRoles.includes(roleKey) && roleKey !== "default");

    // Toggle function for the "More" button
    const toggleShowAll = () => setShowAll(prevState => !prevState);

    return (
        <>
            <section className="section">
                <div className="container">
                    <h1 className="title is-size-3 has-text-centered has-text-weight-normal p-5">
                        Try our flexible <span className="gradient-text">AI Assistant</span> that adapts to your business needs.
                        <br />
                        Explore a few use cases below to see how it works for your organisation.
                    </h1>
                    {/* Multiline Columns for Role Boxes */}
                    <div className="columns is-multiline">
                        {initialRoles.map(roleKey => (
                            <div key={roleKey} className="column is-one-third">
                                <Link to={`/?role=${roleKey}`}>
                                    <RoleSelectionBox name={config.roles[roleKey].name} intro={config.roles[roleKey].intro} />
                                </Link>
                            </div>
                        ))}
                    </div>

                    {/* "More" Button and Separator */}
                    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <button className="button" style={{ backgroundColor: "#ffffff", border: "none", paddingLeft: "0" }} onClick={toggleShowAll}>
                            {showAll ? "▼ Less" : "▶ More"}
                        </button>
                        <hr style={{ backgroundColor: "#808080", marginTop: "10px" }} />
                    </div>

                    {/* Conditionally render the remaining roles below the line when "More" is clicked */}
                    {showAll && (
                        <div className="columns is-multiline">
                            {remainingRoles.map(roleKey => (
                                <div key={roleKey} className="column is-one-third">
                                    <Link to={`/?role=${roleKey}`}>
                                        <RoleSelectionBox name={config.roles[roleKey].name} intro={config.roles[roleKey].intro} />
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default Welcome;
