import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton } from "@fluentui/react";
import AnimatedOrb from "../../components/AnimatedOrb/AnimatedOrb";

import styles from "./Chat.module.css";

import { chatApi, Approaches, AskResponse, ChatRequest, ChatTurn } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleQuestionList } from "../../components/ExampleQuestions";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { UnitySwitch } from "../../components/UnitySwitch";
import { ChatProps } from "../../interfaces/componentProperties";
import { SearchBar } from "../../components/SearchBar/SearchBar";

import { Roles } from "../../../roles";
import rolesJson from "../../../roles.json";

// Import the UnitywebGL component
import { App as UnitywebGL } from "../../components/UnityWebGL"; // Update the path to your UnitywebGL component file

export const getCurrentRoleFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    const roleKey = params.get("role");
    if (roleKey) {
        return roleKey;
    }
    // Check for the brandingLogo cookie if role is not in params
    const brandingLogo = document.cookie.split("; ").find(row => row.startsWith("brandingLogo="));
    return brandingLogo ? brandingLogo.split("=")[1] : "aircraft-engineer";
};

const Chat: React.FC<ChatProps> = ({ selectedRole }) => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    const [isUnityActive, setIsUnityActive] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(true); // New state for SearchBar visibility

    const config: Roles = rolesJson;

    const navigate = useNavigate();

    useEffect(() => {
        const handleRoleChange = () => {
            clearChat(); // Clear the chat when the role changes
            navigate(`/?role=${selectedRole}`); // Update the URL with the selected role
        };

        if (selectedRole) {
            handleRoleChange();
        }
    }, [selectedRole, navigate]);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                skipCache: false,
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };
            const result = await chatApi(selectedRole, request);
            setAnswers([...answers, [question, result]]);
            // When you receive a response from the chat, dispatch a custom event for Unity
            const requestFreshAnswer = async () => {
                // Ensure there is a last question to use
                if (lastQuestionRef.current) {
                    error && setError(undefined);
                    setIsLoading(true);
                    setActiveCitation(undefined);
                    setActiveAnalysisPanelTab(undefined);

                    const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
                    const freshRequest: ChatRequest = {
                        history: [...history, { user: lastQuestionRef.current, bot: undefined }],
                        skipCache: false, // Set skipCache to true only for this request
                        approach: Approaches.ReadRetrieveRead,
                        overrides: {
                            promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                            excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                            top: retrieveCount,
                            semanticRanker: useSemanticRanker,
                            semanticCaptions: useSemanticCaptions,
                            suggestFollowupQuestions: useSuggestFollowupQuestions
                        }
                    };

                    try {
                        const result = await chatApi(selectedRole, freshRequest);
                        setAnswers(prevAnswers => [...prevAnswers, [lastQuestionRef.current, result]]);
                        dispatchChatMessage(result.answer.toString());
                    } catch (e) {
                        setError(e);
                    } finally {
                        setIsLoading(false);
                    }
                }
            };
            dispatchChatMessage(result.answer.toString()); // Replace responseFromChat with your actual response
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to dispatch a custom event for unity
    const dispatchChatMessage = (message: string) => {
        const chatMessageEvent = new CustomEvent("chatMessage", {
            detail: {
                message
            }
        });

        window.dispatchEvent(chatMessageEvent);
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const toggleUnity = async () => {
        // Toggle the isUnityActive state
        setIsUnityActive(prev => !prev);
    };

    const requestFreshAnswer = async () => {
        // Ensure there is a last question to use
        if (lastQuestionRef.current) {
            error && setError(undefined);
            setIsLoading(true);
            setActiveCitation(undefined);
            setActiveAnalysisPanelTab(undefined);

            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const freshRequest: ChatRequest = {
                history: [...history, { user: lastQuestionRef.current, bot: undefined }],
                skipCache: true, // Set skipCache to true only for this request
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };

            try {
                const result = await chatApi(selectedRole, freshRequest);
                setAnswers(prevAnswers => [...prevAnswers, [lastQuestionRef.current, result]]);
                dispatchChatMessage(result.answer.toString());
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    // Function to apply gradient to specific words enclosed in {{}}
    const applyGradientText = (text: string): JSX.Element => {
        const parts = text.split(/(\{\{[^}]+\}\})/g); // Split text by {{...}}

        return (
            <>
                {parts.map((part, index) => {
                    if (part.startsWith("{{") && part.endsWith("}}")) {
                        // Remove the {{ }} and apply gradient class
                        const gradientText = part.slice(2, -2);
                        return (
                            <span key={index} className="gradient-text">
                                {gradientText}
                            </span>
                        );
                    }
                    return part;
                })}
            </>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.chatRoot}>
                {isUnityActive && (
                    <div className={styles.unityContainer}>
                        {/* Pass the isUnityActive prop to the UnitywebGL component */}
                        <UnitywebGL isUnityActive={isUnityActive} />
                    </div>
                )}

                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <p className="title is-size-3 has-text-centered has-text-weight-normal p-5">
                                {applyGradientText(config.roles[selectedRole].info || config.roles[selectedRole].intro)}
                            </p>{" "}
                            {showSearchBar && (
                                <SearchBar
                                    placeholder="Type your question here..."
                                    onSend={question => {
                                        makeApiRequest(question);
                                        setShowSearchBar(false); // Hide the search bar after sending
                                    }}
                                    disabled={isLoading}
                                />
                            )}
                            <ExampleQuestionList onExampleClicked={onExampleClicked} />
                            <h3 className={styles.chatEmptyStateTest}></h3>
                        </div>
                    ) : (
                        <div className={styles.chatBoxContainer}>
                            <div className={styles.chatMessageStream}>
                                {answers.map((answer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={answer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                key={index}
                                                answer={answer[1]}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                onRequestFreshAnswer={requestFreshAnswer}
                                            />
                                        </div>
                                    </div>
                                ))}
                                {isLoading && (
                                    <>
                                        <UserChatMessage message={lastQuestionRef.current} />
                                        <div className={styles.chatMessageGptMinWidth}>
                                            <AnswerLoading />
                                        </div>
                                    </>
                                )}
                                {error ? (
                                    <>
                                        <UserChatMessage message={lastQuestionRef.current} />
                                        <div className={styles.chatMessageGptMinWidth}>
                                            <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                        </div>
                                    </>
                                ) : null}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                            <div className={`${styles.chatInput} pb-0 px-3`}>
                                <QuestionInput
                                    clearOnSend
                                    placeholder="Type your question here"
                                    disabled={isLoading}
                                    onSend={question => makeApiRequest(question)}
                                    onClearChat={clearChat} // Pass the clearChat function
                                    onToggleUnity={toggleUnity} // Pass the toggleUnity function
                                    isUnityActive={isUnityActive} // Pass the current state of Unity
                                />
                            </div>
                        </div>
                    )}
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    {/* <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    /> */}
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
