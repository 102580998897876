import { useCallback, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl"; // Make sure to import UnityProvider
import styles from "./UnityWebGL.module.css";
import config from "../../../roles.json";

// when working on unitywebGL please use version 2021.3.26f1 to avoid a known error regarding boundingClient
// detailed here https://mesmeriseglobal.atlassian.net/browse/DAS-1952
// solution was found at https://forum.unity.com/threads/getboundingclientrect-problem.1302099/
// Also BuildSettings > PlayerSettings > Publishing Settings > Enable Exceptions > Full without StackTrace

const App = ({ isUnityActive = false }: { isUnityActive: boolean }) => {
    const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, removeEventListener, unload } = useUnityContext({
        loaderUrl: "/UnityBuild/Build.loader.js",
        dataUrl: "/UnityBuild/Build.data.gz",
        frameworkUrl: "/UnityBuild/Build.framework.js.gz",
        codeUrl: "/UnityBuild/Build.wasm.gz",
        webglContextAttributes: {
            preserveDrawingBuffer: true
        }
    });

    // Add a flag to control whether to enable or disable keyboard input
    const [disableKeyboardInput, setDisableKeyboardInput] = useState(false);

    // Conditionally handle keyboard input based on the flag
    const handleKeyboardInput = (event: KeyboardEvent) => {
        if (disableKeyboardInput || !isUnityActive) {
            event.preventDefault(); // Prevent default keyboard behavior
        }
        // Handle the keyboard input as needed for your application
    };

    const [currentRole, setCurrentRole] = useState<string | null>(null);

    // -- CHANGE BACKGROUND
    const changeBackground = (role: string) => {
        // Use type assertion to ensure the background property exists
        const backgroundUrl =
            window.location.origin +
                (config.roles[role as keyof typeof config.roles] as { logo: string; background: string; questions: string[] })?.background ||
            config.roles.default.background;

        //console.log("backgroundUrl is:", backgroundUrl);

        if (backgroundUrl) {
            //console.log("Changing background to:", backgroundUrl);
            sendMessage("Avatar2", "ChangeBackground", backgroundUrl);
        } else {
            console.error("Background URL not found for role:", role);
        }
    };

    useEffect(() => {
        const roleKey = new URLSearchParams(window.location.search).get("role") || "default";
        setCurrentRole(roleKey);
    }, [window.location.search]);

    const resetSpeech = () => {
        sendMessage("Avatar2", "SpeechToText", "");
    };

    // event for clearing speech2text
    useEffect(() => {
        const handleClearChat = () => {
            if (isLoaded) {
                // Check if Unity is loaded
                resetSpeech();
            } else {
                console.warn("Unity is not loaded yet.");
            }
        };

        window.addEventListener("clearChatEvent", handleClearChat);

        return () => {
            window.removeEventListener("clearChatEvent", handleClearChat);
        };
    }, [isLoaded]); // Add isLoaded to the dependencies array

    useEffect(() => {
        if (isUnityActive && isLoaded && currentRole) {
            resetSpeech(); // Reset the speech whenever the role changes
            setTimeout(() => {
                changeBackground(currentRole);
            }, 1000);
        }
    }, [currentRole, isUnityActive, isLoaded]);

    // -- TEXT TO SPEECH for response
    function text2speech(response: string) {
        try {
            if (response) {
                // Remove references and any text enclosed in square brackets that contains .pdf
                let cleanedResponse = response
                    .replace(/Reference:.*$/, "")
                    .replace(/\[.*?\.pdf\]/g, "")
                    .trim();

                // Perform basic regex parsing to remove hyphens and underscores
                cleanedResponse = cleanedResponse.replace(/[-_]/g, " ");

                console.log("VA Response Received: " + cleanedResponse);
                sendMessage("Avatar2", "SpeechToText", cleanedResponse);
            } else {
                console.error("Error: Empty response received.");
            }
        } catch (error: any) {
            if (error instanceof Error) {
                console.error("An error occurred:", error.message);
            } else {
                console.error("An unknown error occurred.");
            }
        }
    }

    useEffect(() => {
        // Function to handle messages from the chat component
        const handleChatMessage = (event: any) => {
            const response = event.detail.message;

            // Call your Unity text-to-speech function here, passing the response
            text2speech(response);
        };

        // Register the event listener for messages from the chat component
        window.addEventListener("chatMessage", handleChatMessage);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("chatMessage", handleChatMessage);
        };
    }, [addEventListener, removeEventListener, text2speech]);

    // --- CLOSE UNITY
    useEffect(() => {
        // Unload Unity when isUnityActive is false
        if (!isUnityActive) {
            unload();
        }

        // Clean up the event listener when the component unmounts
        return () => {
            // Unload Unity if it has not been unloaded already
            if (isUnityActive) {
                unload();
            }
        };
    }, [isUnityActive, unload]);

    return (
        <div className={styles.container}>
            <div className={styles.unityWrapper}>
                {isLoaded === false && (
                    <div className={styles.loadingBar}>
                        <div className={styles.loadingBarFill} style={{ width: loadingProgression * 100 }} />
                    </div>
                )}
                {isUnityActive && <Unity unityProvider={unityProvider} style={{ visibility: isLoaded ? "visible" : "hidden" }} />}
            </div>
        </div>
    );
};

export { App };
