// src/components/SearchBar.tsx
import React, { useState } from "react";
import { TextField } from "@fluentui/react";
import styles from "./SearchBar.module.css"; // Import the CSS module

interface Props {
    placeholder?: string;
    disabled?: boolean;
    onSend?: (value: string) => void; // onSend is optional
}

export const SearchBar: React.FC<Props> = ({ placeholder, disabled = false, onSend }) => {
    const [text, setText] = useState<string>("");

    const handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setText(newValue || "");
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            if (text.trim() && onSend) {
                onSend(text);
                setText(""); // Clear text after sending
            }
        }
    };

    return (
        <div className={styles.searchBarWrapper}>
            <div className={styles.searchBarContainer}>
                <TextField
                    className={styles.searchInput}
                    placeholder={placeholder}
                    value={text}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    disabled={disabled}
                    borderless
                    styles={{
                        field: {
                            padding: "0", // Remove default padding
                            border: "none",
                            boxShadow: "none",
                            fontSize: "inherit"
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default SearchBar;
