import React, { useState, useEffect } from "react";
import { useLocation, Routes, Route, Link } from "react-router-dom";
import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import About from "./pages/about/About";
import Welcome from "./pages/welcome/Welcome";
import { initializeIcons } from "@fluentui/react/lib/Icons";
initializeIcons(/* optional base url */);

const App = () => {
    const location = useLocation();
    const [selectedRole, setSelectedRole] = useState("default");

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const roleKey = params.get("role");
        setSelectedRole(roleKey || "default");
    }, [location.search]);

    // show the welcome screen?
    const showWelcomeScreen = selectedRole === "default" && location.pathname === "/";

    return (
        <div>
            {showWelcomeScreen ? (
                <Welcome />
            ) : (
                <Routes>
                    <Route path="/" element={<Layout selectedRole={selectedRole} />}>
                        <Route index element={<Chat selectedRole={selectedRole} />} />
                        <Route path="about" element={<About />} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            )}
        </div>
    );
};

export default App;
