import styles from "./AnimatedOrb.module.css";

interface Props {
    width?: number;
    animated?: boolean;
}

const AnimatedOrb = ({ width = 100, animated = true }: Props) => {
    const spanStyle = {
        width: `${width}px`,
        height: `${width}px`
    };

    //console.log("animation is + ", animated);

    const offset = width * 0.1;

    return (
        <>
            <style>
                {`
                .${styles.loader} {
                    height: ${width}px;
                    width: ${width}px;
                }

                .${styles.loader}::after {
                    content: "";
                    position: absolute;
                    top: ${offset}px;
                    left: ${offset}px;
                    right: ${offset}px;
                    bottom: ${offset}px;
                    border-radius: 50%;
                } 
            `}
            </style>
            <div className={styles.loader} style={{ ...spanStyle, ...(animated ? {} : { animation: "none" }) }}>
                <span style={spanStyle}></span>
                <span style={spanStyle}></span>
                <span style={spanStyle}></span>
                <span style={spanStyle}></span>
            </div>
        </>
    );
};

export default AnimatedOrb;
