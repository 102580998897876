// import React from "react";
// import styles from "./RoleSelectionBox.module.css";

// interface props {
//     name: string;
//     logo: string;
//     intro: string;
// }

// const RoleSelectionBox: React.FC<props> = ({ name, logo, intro }) => {
//     return (
//         <>
//             <div className={`box has-background-black has-text-centered py-6 is-clickable ${styles.customHoverBox}`}>
//                 <img src={logo} alt={`${name} Logo`} className="mb-3" />
//                 <hr className={`mt-0 mb-0 ${styles.horizontalRule}`} />
//                 <p className="mt-3 is-size-7 has-text-white">{intro}</p>
//             </div>
//         </>
//     );
// };

// export default RoleSelectionBox;

import React from "react";
import styles from "./RoleSelectionBox.module.css";

interface Props {
    name: string;
    intro: string;
}

const RoleSelectionBox: React.FC<Props> = ({ name, intro }) => {
    return (
        <div
            className={`box has-text-left py-6 is-clickable ${styles.customHoverBox}`}
            style={{ backgroundColor: "#F4F4F4", height: "250px", display: "flex", flexDirection: "column" }}
        >
            <p className="mb-2 has-text-weight-bold" style={{ color: "#D563E0", fontSize: "1.5rem" }}>
                {name}
            </p>
            <hr className="mt-0 mb-0" style={{ backgroundColor: "white" }} />
            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <p className="mt-0 is-size-6 has-text-black has-text-left" style={{ padding: "0", margin: "0" }}>
                    {intro}
                </p>
            </div>
        </div>
    );
};

export default RoleSelectionBox;
