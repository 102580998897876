import axios, { AxiosError } from "axios";
import Cookie from "universal-cookie";

export async function getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get("speech-token");

    if (speechToken === undefined) {
        try {
            const res = await axios.get("/api/get-speech-token");
            const token = res.data.token;
            const region = res.data.region;
            //cookie.set("speech-token", region + ":" + token, { maxAge: 540, path: "/" });

            //console.log("Token fetched from back-end: " + token);
            return { authToken: token, region: region };
        } catch (err) {
            // Use type assertion to tell TypeScript that 'err' is an AxiosError
            const axiosError = err as AxiosError;

            if (axiosError.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(axiosError.response.data);
                return { authToken: null, error: axiosError.response.data };
            } else if (axiosError.request) {
                // The request was made but no response was received
                console.log(axiosError.request);
                return { authToken: null, error: "No response from the server" };
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error message:", axiosError.message);
                return { authToken: null, error: axiosError.message };
            }
        }
    } else {
        //console.log("Token fetched from cookie: " + speechToken);
        const idx = speechToken.indexOf(":");
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}
