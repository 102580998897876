import React from "react";
import { Text } from "@fluentui/react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "./UnitySwitch.module.css";

import { styled } from "@mui/material/styles";

interface UnitySwitchProps {
    isUnityActive: boolean;
    onToggleUnity: () => void;
}

const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#EE75FF",
        "&:hover": {
            backgroundColor: "rgba(238, 117, 255, 0.08)"
        }
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#EE75FF"
    }
}));

export const UnitySwitch: React.FC<UnitySwitchProps> = ({ isUnityActive, onToggleUnity }) => {
    return (
        <div className={styles.container}>
            <FormControlLabel
                control={<CustomSwitch checked={isUnityActive} onChange={onToggleUnity} />}
                label={
                    <Text className={`${styles.switchLabel} ${isUnityActive ? styles.active : styles.inactive}`} variant="medium">
                        {isUnityActive ? "Avatar On" : "Avatar Off"}
                    </Text>
                }
                labelPlacement="start"
            />
        </div>
    );
};
